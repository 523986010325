<script>
import router from "@/router";
import { SimpleBar } from "simplebar-vue3";
import {authComputed, layoutComputed} from "@/state/helpers";

import NavBar from "@/components/nav-bar";
import Menu from "@/components/menu.vue";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";

import moment from "moment/moment";

localStorage.setItem('hoverd',false);

/**
 * Vertical layout
 */
export default {
  components: { NavBar, RightBar, Footer, SimpleBar, Menu },
  data() {
    return {
      isMenuCondensed: false,
      firstSubscriptionWarningDaysLeft: 10,
      lastSubscriptionWarningDaysLeft: 3,

    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    subscriptionDaysLeft () {
      if (!this.currentUser) {
        return false;
      }
      if(!this.currentUser.subscription_period_end) {
        return false;
      }

      let subscriptionEnds = moment(this.currentUser.subscription_period_end);
      let currentDate = moment(new Date());
      let daysLeft = subscriptionEnds.diff(currentDate, 'days');
      console.log(daysLeft);
      // return 1;

      return daysLeft <= 0? 0 : daysLeft;
    },
  },
  created: () => {
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    initActiveMenu() {
      if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
        localStorage.setItem('hoverd',true)
        document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
      } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
        localStorage.setItem('hoverd',false)
        document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
      } else {
        document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
      }
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
    if(localStorage.getItem('hoverd')=='true'){
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
    }
    document.getElementById('overlay').addEventListener('click',()=>{
      document.body.classList.remove('vertical-sidebar-enable')
    })
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar />
    <div>
      <!-- ========== Left Sidebar Start ========== -->
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/dashboard" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="32" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="30" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/dashboard" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="32" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="30" />
            </span>
          </router-link>
          <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover" @click="initActiveMenu">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <SimpleBar id="scrollbar" class="h-100" ref="scrollbar">
          <Menu></Menu>
        </SimpleBar>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div class="vertical-overlay" id="overlay"></div>
    </div>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
        <!-- Start Content-->
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-6" v-show="subscriptionDaysLeft <= firstSubscriptionWarningDaysLeft && subscriptionDaysLeft > lastSubscriptionWarningDaysLeft">
              <div class="card">
                <div class="card-body p-0">
                  <div class="alert alert-warning alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                    <i class="ri-information-line label-icon"></i>
                    <div class="flex-grow-1 text-truncate">
                      {{ $t("t-subscription_will_end_in") }} <b>{{ subscriptionDaysLeft }}</b> {{ $t("t_in_days")}}.
                    </div>
                    <div class="flex-shrink-0">
                      <a href="/subscription-plan" class="text-reset text-decoration-underline"><b>{{ $t("t-upgrade") }}</b></a>
                    </div>
                  </div>
                </div> <!-- end card-body-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6" v-show="subscriptionDaysLeft <= lastSubscriptionWarningDaysLeft">
              <div class="card bg-primary">
                <div class="card-body p-0">
                  <div class="alert alert-danger alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                    <i class="ri-error-warning-line label-icon"></i>
                    <div class="flex-grow-1 text-truncate" v-show="subscriptionDaysLeft > 0">
                      {{ $t("t-subscription_will_end_in") }} <b>{{ subscriptionDaysLeft }}</b> <span v-if="subscriptionDaysLeft === 1">{{ $t("t_in_day")}}</span> <span v-if="subscriptionDaysLeft > 1">{{ $t("t_in_days")}}</span>.
                    </div>

                    <div class="flex-grow-1 text-truncate" v-show="subscriptionDaysLeft === 0">
                      {{ $t("t_inactive_subscription_message") }}
                    </div>

                    <div class="flex-shrink-0">
                      <a href="/subscription-plan" class="text-reset text-decoration-underline"><b>{{ $t("t-upgrade") }}</b></a>
                    </div>
                  </div>

                </div> <!-- end card-body-->
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
    <RightBar />
  </div>
</template>
