<script>
import i18n from "../i18n";
import {authComputed, authMethods} from "@/state/helpers";
import LanguageSelector from "@/components/language-selector";
import { isIosApp } from "webtonative";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      languages: [{
        flag: require("@/assets/images/flags/en.svg"),
        language: "en",
        title: "English",
      },
        {
          flag: require("@/assets/images/flags/ru.svg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/pl.svg"),
          language: "pl",
          title: "Polski",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
    };
  },
  components: {
    LanguageSelector
    // SimpleBar
  },

  methods: {
    ...authMethods,
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    setTheme(theme) {
      document.documentElement.setAttribute("data-layout-mode", theme);
    },
    toggleDarkMode() {
      let theme;
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        theme = 'light';
      } else {
        theme = 'dark';
      }
      this.setTheme(theme)
      localStorage.setItem('theme', theme)
    },
  },
  computed: {
    isIos() {
      return isIosApp
    },
    ...authComputed,
  },
  mounted() {
    let locale = localStorage.getItem('locale')
    let country = localStorage.getItem('country')
    let flag = localStorage.getItem('flag')

    if (locale && country && flag) {
      this.setLanguage(locale, country, flag);
    }

    let theme = localStorage.getItem('theme')

    if (theme) {
      this.setTheme(theme);
    }

    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
            "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
      }
    });
    if (document.getElementById("topnav-hamburger-icon"))
      document
      .getElementById("topnav-hamburger-icon")
      .addEventListener("click", this.toggleHamburgerMenu);

    // this.isCustomDropdown();
  },
};
</script>

<template>
  <header id="page-topbar" style="border-bottom: 1px solid #ddd;">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="32" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="40" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="32" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="30" />
              </span>
            </router-link>
          </div>
                    <button type="button" class="
                        btn btn-sm
                        px-3
                        fs-16
                        header-item
                        vertical-menu-btn
                        topnav-hamburger
                      " id="topnav-hamburger-icon">
                      <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                    </button>
        </div>

        <div class="d-flex align-items-center">
          <language-selector></language-selector>

          <div class="ms-1 header-item d-sm-flex">
            <button type="button" class=" btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
                    @click="toggleDarkMode">
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" src="@/assets/images/users/user-default-img.jpg"
                     alt="Header Avatar" />
                <span class="text-start ms-xl-2">
                  <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{currentUser ? currentUser.username : '' }}</span>
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <router-link class="dropdown-item" to="/profile">
                <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{ $t("t-profile") }}</span>
              </router-link>

<!--              <router-link v-if="! isIos" class="dropdown-item" to="/subscription-plan">-->
<!--                <i class="ri-ticket-2-line text-muted fs-16 align-middle me-1"></i>-->
<!--                <span class="align-middle">{{ $t("t-subscription-plan") }}</span>-->
<!--              </router-link>-->

<!--              <router-link v-if="! isIos" class="dropdown-item" to="/profile/referral-program">-->
<!--                <i class="ri-group-line text-muted fs-16 align-middle me-1"></i>-->
<!--                <span class="align-middle">{{ $t("t-referral-program") }}</span>-->
<!--              </router-link>-->

              <div class="dropdown-divider"></div>

              <a class="dropdown-item" href="/login" v-if="loggedIn" @click="logOut">
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">{{ $t("t-logout") }}</span>
              </a>

<!--              <div class="dropdown-divider"></div>-->

<!--              <span class="dropdown-item bg-warning">-->
<!--                  ffffff-->
<!--              </span>-->

            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
